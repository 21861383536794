.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.box {
  position: relative;
  width: 3rem;
  height: 3rem;
  border: 1px solid grey;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px; /* Add margin between each big box */

}

.small-box.above {
  top: -1px;
}

.small-box.below {
  bottom: -22px;
}

.small-box {
  position: absolute;
  top: 70%;
  transform: translate(-100%, -10%); /* Position the small box to the left of the big box */
  width: 1rem;
  font-size: 0.66rem;
  height: 1rem;
  background-color: darkgray;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 11px;
  
}

.small-box.green {
  background-color: green;
}

.small-box.yellow {
  background-color: #ffd600;
}

.small-box.grey {
  background-color: darkgray;
}

.box.yellow {
  background-color: #ffd600;
}

.box.grey {
  background-color: grey;
}

.box.green {
  background-color: green;
}

.replay-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.replay-button:hover {
  background-color: #0056b3;
}
