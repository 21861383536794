

.customInput {
    height: 5rem;
    width: 10rem;
}

.react-datepicker-wrapper{height:auto}

.react-datepicker__month-container{
    background-color: white;
}

.react-datepicker__input-container  {

    height: 2.5rem;
  }
  .react-datepicker__input-container input {

    height: 2.5rem;
  }