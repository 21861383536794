.img-overlay-wrap {
  position: relative;
  display: inline-block; /* <= shrinks container to image size */
  /* transition: transform 150ms ease-in-out; */
}

.img-overlay-wrap img { /* <= optional, for responsiveness */
   display: block;
   max-width: 100%;
   height: auto;
}

.img-overlay-wrap svg {
  position: absolute;
  top: 0;
  left: 0;
}

/* .img-overlay-wrap:hover {
  transform: rotate( 15deg );
} */