.map-container {
    height: 350px;
    width: 800px;
    border-radius: "0.8rem";

  }
  
  .sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 5;
    left: 0;
    margin-left: 380px;
    border-radius: 4px;
  }
  